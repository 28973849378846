import React from "react"
import { graphql } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import altStyles from "../templates/WhatWeDo.module.css"
import styles from "./Events.module.css"
import HubspotForm from "components/HubspotForm"

// eslint-disable-next-line
export const EventsPage = ({ data, oldEvents, headerImage, ...props }) => (
  <Layout {...props} seo={{ title: "OpsGuru Events | Cloud Insights from Industry Experts", description: "Sign up for an upcoming OpsGuru event to learn the latest cloud insights from Canada's leading team of multi-cloud experts." }}>
    <Container className={altStyles.wrapper}>
      <div>
        <Title className={altStyles.bannertitle}>Virtual & In-Person Events</Title>
        <p className={`${altStyles.title} ${altStyles.titlesmall}`}>Unlock the Latest Cloud Insights From Industry Experts </p>
        <p className={altStyles.description}>The world of cloud computing is full of innovation. Sign up for an upcoming OpsGuru event to learn how your business can extend, expand, and evolve in the cloud from our world-class cloud experts.</p>
      </div>
      {headerImage ? (
        <div className={altStyles.image}>
          <img alt="Events" title="Events" src={headerImage.publicURL} loading="lazy" height="420" width="560" />
        </div>
      ) : null}
    </Container>

    <div className={styles.gray}>
    <Container className={styles.posts}>
      <h2 className={styles.postsTitle}>Upcoming Events</h2>
      {(data || [])
        .filter(Boolean)
        .filter(({ node }) =>
          props.pageContext.tag
            ? (node.frontmatter.tags || []).includes(props.pageContext.tag)
            : true
        )
        .map(({ node }, i) => (
          <article className={styles.post} key={i}>
            <div className={styles.postimage}>
              {node.frontmatter.image ? (
                  <Img
                    loading="lazy"
                    objectFit="cover"
                    alt={node.frontmatter.title}
                    title={node.frontmatter.title}
                    fluid={node.frontmatter.image.childImageSharp.fluid}
                  />
              ) : null}
              <div className={styles.posttags}>
                {(node.frontmatter.tags || []).filter(Boolean).map((tag) => (
                  <span>{tag}</span>
                ))[0]}
              </div>
              <time
                  dateTime={node.frontmatter.date}
                  dangerouslySetInnerHTML={{ __html: marked(node.frontmatter.eventDate) }}
              />
            </div>
            <div className={styles.info}>
              <h2>{node.frontmatter.title}</h2>
              <h3>{node.frontmatter.subTitle}</h3>
              {node.frontmatter.description ? (
                <div
                  className={styles.desc}
                  dangerouslySetInnerHTML={{ __html: marked(node.frontmatter.description) }}
                />
              ) : null}
              {node.frontmatter.buttonText ? (
                <a className={styles.button} href={node.frontmatter.eventLink} target="_blank" rel="noreferrer">{node.frontmatter.buttonText}</a>
              ) : null}
            </div>
          </article>
        ))}
      </Container>
      </div>

      <Container className={styles.posts}>
      <h2 className={styles.postsTitle}>Past Events</h2>
      {(oldEvents || [])
        .filter(Boolean)
        .filter(({ node }) =>
          props.pageContext.tag
            ? (node.frontmatter.tags || []).includes(props.pageContext.tag)
            : true
        )
        .map(({ node }, i) => (
          <article className={styles.post} key={i}>
            <div className={styles.postimage}>
              {node.frontmatter.image ? (
                  <Img
                    loading="lazy"
                    objectFit="cover"
                    alt={node.frontmatter.title}
                    title={node.frontmatter.title}
                    fluid={node.frontmatter.image.childImageSharp.fluid}
                  />
              ) : null}
              <div className={styles.posttags}>
                {(node.frontmatter.tags || []).filter(Boolean).map((tag) => (
                  <span>{tag}</span>
                ))[0]}
              </div>
              <time
                  dateTime={node.frontmatter.date}
                  dangerouslySetInnerHTML={{ __html: marked(node.frontmatter.eventDate) }}
              />
            </div>
            <div className={styles.info}>
              <h2>{node.frontmatter.title}</h2>
              <h3>{node.frontmatter.subTitle}</h3>
              {node.frontmatter.description ? (
                <div
                  className={styles.desc}
                  dangerouslySetInnerHTML={{ __html: marked(node.frontmatter.description) }}
                />
              ) : null}
              {node.frontmatter.buttonText ? (
                <a className={styles.button} href={node.frontmatter.eventLink} target="_blank" rel="noreferrer">{node.frontmatter.buttonText}</a>
              ) : null}
            </div>
          </article>
        ))}
    </Container>

    <div className={altStyles.grayBackground} id="contact-form">
      <Container>
      <div className={altStyles.titleContainer}>
          <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span>Learn more about how OpsGuru can help you with your cloud journey</TitleBlock>
        </div>
        <HubspotForm id="b586e1c3-2aa2-4cce-815e-a8fb546726bb" portalId="6237410" />
      </Container>
    </div>
  </Layout>
)

// eslint-disable-next-line
export const EventsPageWrapper = (props) => (
  <EventsPage
    {...props}
    data={props.data.eventPosts.edges}
    oldEvents={props.data.oldEventPosts.edges}
    headerImage={props.data.headerImage}
  />
)

export default EventsPageWrapper

export const pageQuery = graphql`
  query EventsPage($skip: Int!, $limit: Int!) {
    headerImage: file(relativePath: { eq: "banner-events.svg" }) {
      publicURL
    }
    eventPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { 
        frontmatter: {
          templateKey: { eq: "events" } 
        },
        isFuture: { eq: true }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            url
            title
            subTitle
            description
            tags
            date
            buttonText
            eventDate
            eventLink
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    oldEventPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: {
          templateKey: { eq: "events" } 
        },
        isFuture: { eq: false }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            url
            title
            subTitle
            description
            tags
            date
            buttonText
            eventDate
            eventLink
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
